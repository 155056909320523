<template>
  <div class="flex mb-6 flex-col shadow-lg">
    <!-- Column Name -->
    <div class="relative mr-6">
      <p
        class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-2"
      >
        {{ $t('name.column') }}
      </p>
      <div>
        <el-input
          :value="columnName"
          :style="
            isNameMissing ? 'border: 1px solid #F56C6C ; border-radius:4px' : ''
          "
          @input="$emit('changeColumnName', $event)"
        ></el-input>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="isNameMissing"
          >
            {{ $t('formula.errors.name') }}
          </div>
        </transition>
      </div>
    </div>
    <!-- textArea -->
    <div class="flex items-center mb-2 mt-5">
      <p
        class="text-annotationColor text-sm font-SourceSansPro font-semibold mr-2"
      >
        {{ $t('objects_tabs.sensors.formula.columns') }}
      </p>
      <el-tooltip
        :open-delay="700"
        effect="dark"
        :content="$t('formula.tooltip')"
        placement="top"
        :offset="2"
        :visible-arrow="true"
        popper-class="formulaTooltip"
      >
        <TooltipIcon />
      </el-tooltip>
    </div>
    <FormulaTags
      :values="values"
      :is-saved="isSaved"
      @deleteTag="$emit('deleteTag', $event)"
      @addOperand="$emit('addOperand', $event)"
      @onEnterValue="$emit('onEnterValue', $event)"
      @onDragTag="$emit('onDragTag', $event)"
      @onFinishEnterValue="isActionFinished = !isActionFinished"
    />
    <FormulaDropdown
      :formulas="formulas"
      :show-formula-dropdown="showFormulaDropdown"
      :search-input="searchInput"
      @toggleFormulaDropdown="$emit('toggleFormulaDropdown')"
      @changeSearchInput="$emit('changeSearchInput', $event)"
      @addFormulaToTextarea="$emit('addFormulaToTextarea', $event)"
    />
    <div class="relative mt-4 mr-4">
      <div class="flex switch items-center">
        <p class="mr-2 sensor-reports-text">
          {{ $t('reportEdit.sensor.addTotalValue') }}
        </p>
        <!-- <el-switch
              :disabled="editedColumn.id !== column.id"
              :value="column.isHaveSensor"
              @change="changeEditSensorColumn(column)"
              class="mr-4"
            /> -->
        <div class="info" @focus="showInfoText" @blur="hideInfoText">
          <QuestionIcon />
          <p class="info-text hidden">
            {{ $t('reportEdit.sensor.hintText') }}
          </p>
        </div>
      </div>
      <div class="select w-full mt-4">
        <el-select
          class="w-full"
          v-model="sensorColumn"
          :placeholder="$t('new_units.placeholder.select')"
          @change="selectSensor"
        >
          <el-option
            v-for="item in getSensorValues"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          />
        </el-select>
      </div>
    </div>
    <div class="flex mt-6 mr-4 border-b border-reallylightblue pb-4">
      <skif-button class="flex-1" @click="addFormula"
        >{{ $t('formula.add') }}
      </skif-button>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useStore } from '@/store/store.js'
import TooltipIcon from './assets/TooltipIcon.vue'
import FormulaDropdown from './FormulaDropdown.vue'
import FormulaTags from './FormulaTags.vue'
import QuestionIcon from '@/components/unitsNew/assets/icons/question-icon.vue'

const store = useStore()

const getSensorValues = store.getters['dictionary/getSensorValues']

const emits = defineEmits(['onEnterValue', 'addFormula', 'changeAddSensor'])

const props = defineProps({
  columnName: {
    type: String,
    default: ''
  },
  textarea: {
    type: String,
    default: ''
  },
  searchInput: {
    type: String,
    default: ''
  },
  showFormulaDropdown: {
    type: Boolean,
    default: false
  },
  isNameMissing: {
    type: Boolean,
    default: false
  },
  formulas: {
    type: Array,
    default: () => []
  },
  index: {
    type: Number,
    default: null
  },
  editedIndex: {
    type: Number,
    default: null
  },
  isFormulaWrong: {
    type: Boolean,
    default: false
  },
  isFormulaMissing: {
    type: Boolean,
    default: false
  },
  values: {
    type: Array,
    default: () => []
  }
})

const showInfoText = () => {
  const info = document.querySelector('.info')
  info.classList.add('hovered')
}

const sensorColumn = ref('')

const hideInfoText = () => {
  const info = document.querySelector('.info')
  info.classList.remove('hovered')
}

const selectSensor = () => {
  emits('changeAddSensor', sensorColumn.value)
}

const isSaved = ref(false)
const isActionFinished = ref(false)

const addFormula = () => {
  isSaved.value = !isSaved.value
}

const addFormulaHandler = () => {
  emits('addFormula')
}

watch(isActionFinished, () => {
  addFormulaHandler()
})
</script>

<style lang="styl" scoped>
.info-text {
  position: absolute;
  right: 20px;
  width 295px;
  z-index: 10;
  margin-top: 5px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #2767B6;
  background: #DBEBFF;
  border-radius: 6px;
}
.info:hover .info-text {
  display: block;
}

.sensor-reports-text {
  color: #80ABE1;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
</style>
