<template>
  <div>
    <div
      class="flex flex-wrap gap-2 border border-normalButton rounded formula-field-wrapper mr-6 p-3"
      :class="{ 'bg-hoverrow': isDisabled }"
      @click="wrapperClick"
    >
      <draggable
        v-model="tags"
        :disabled="isDisabled"
        class="flex flex-wrap gap-2"
        @change="dragTag"
      >
        <el-tag
          v-for="(tag, idx) in tags"
          :key="idx"
          :closable="!isDisabled"
          class="text-mainTitleColor"
          @close="$emit('deleteTag', { tag, idx })"
          @click.stop
        >
          {{ tag.name }}
        </el-tag>
      </draggable>
      <input
        v-if="!isDisabled"
        v-model="numberOperand"
        type="number"
        ref="operandInput"
        class="operand-input"
        @keypress.space.enter="handleEnterValue"
      />
    </div>

    <div v-if="!isDisabled" class="flex gap-2 mt-2">
      <div
        v-for="operand in operations"
        :key="operand"
        class="w-8 h-8 rounded-md bg-lightGrey-200 flex items-center justify-center cursor-pointer text-blue-200"
        @click="handleAddOperand(operand)"
      >
        {{ operand }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch } from 'vue'

import draggable from 'vuedraggable'

const emits = defineEmits(['onEnterValue', 'onDragTag', 'addOperand'])
const props = defineProps({
  values: {
    type: Array,
    default: () => []
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  isSaved: {
    type: Boolean,
    default: false
  }
})

const operandInput = ref(null)
const numberOperand = ref(null)
const operations = ['+', '-', '*', '/', '(', ')']

const tags = ref([])

const enterValue = () => {
  emits('onEnterValue', numberOperand.value)

  numberOperand.value = null
}

const handleEnterValue = () => {
  enterValue()
}

const wrapperClick = () => {
  if (!operandInput.value) return
  operandInput.value.focus()
}

const dragTag = (el) => {
  emits('onDragTag', tags.value)
}

const handleAddOperand = (operand) => {
  if (numberOperand.value) {
    enterValue()
  }

  emits('addOperand', operand)
}

watch(
  () => props.values,
  () => {
    tags.value = props.values
  }
)

watch(
  () => props.isSaved,
  () => {
    if (numberOperand.value) {
      enterValue()
    }

    emits('onFinishEnterValue')
  }
)

onBeforeMount(() => {
  tags.value = props.values
})
</script>

<style scoped lang="scss">
.formula-field-wrapper {
  min-height: 54px;
}
.operand-input {
  width: 20%;
  &:focus {
    outline: none;
  }
}
</style>
