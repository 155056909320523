<template>
  <div class="col-start-1 col-end-3 sensors">
    <!-- Add Formula -->
    <transition name="el-zoom-in-top">
      <FormulaList v-if="isAdd" :column-name="columnName" :textarea="textarea" :search-input="searchInput" :showFormulaDropdown="showFormulaDropdown" :formulas="filteredFormulas" :isNameMissing="isNameMissing" :isFormulaMissing="isFormulaMissing" :values="textareaValues" @changeColumnName="changeColumnName" @changeTextareaValue="changeTextareaValue" @changeSearchInput="changeSearchInput" @toggleFormulaDropdown="toggleFormulaDropdown" @addFormulaToTextarea="addFormulaToTextarea" @addFormula="addFormula" @deleteTag="deleteParamFromFormula" @addOperand="addOperand" @onEnterValue="onEnterValue" @onDragTag="dragTag" @changeAddSensor="setSensorColumn" />
    </transition>

    <div v-for="(column, index) in currentColumns" :key="index" class="flex px-4 pt-4 mb-6 pb-6 flex-col border">
      <!-- Column Name -->
      <div class="relative">
        <p class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-2">
          {{ $t('name.column') }}
        </p>
        <div class="flex items-center gap-4">
          <el-input v-model="column.name" :disabled="editedIndex === null || editedIndex !== index" />
          <div class="flex bg-red-700 items-center gap-2">
            <EditIcon class="cursor-pointer" @click.native="editFormula(column, index)" />
            <DeleteBtn class="cursor-pointer" @click.native="removeFormula(index, column)" />
          </div>
        </div>

        <!-- textArea -->

        <div class="relative mt-2">
          <p class="text-annotationColor text-sm font-SourceSansPro font-semibold mb-2 mr-2">
            {{ $t('objects_tabs.sensors.formula.columns') }}
          </p>
          <FormulaTags :values="column.textareaValues" :is-saved="column.isEdited" :is-disabled="editedIndex === null || editedIndex !== index" @deleteTag="deleteParamFromFormula($event, index)" @addOperand="addOperand($event, index)" @onEnterValue="onEnterValue($event, index)" @onDragTag="dragTag($event, index)" @onFinishEnterValue="isActionFinished = !isActionFinished" />
          <transition name="el-zoom-in-top">
            <div class="text-notify text-xs top-full left-0 absolute" v-if="isFormulaWrong && editedIndex === index">
              <span>
                {{ formulaErrorMsg }}
              </span>
            </div>
          </transition>
        </div>
        <FormulaDropdown v-if="isEdit && editedIndex === index" :formulas="filteredFormulas" :show-formula-dropdown="column.showFormulaDropdown" :search-input="searchInput" @changeSearchInput="changeSearchInput" @toggleFormulaDropdown="column.showFormulaDropdown = !column.showFormulaDropdown" @addFormulaToTextarea="editFormulaToTextarea($event, index)" />
        <div class="relative mt-4">
          <div class="flex switch items-center">
            <p class="mr-2 sensor-reports-text">
              {{ $t('reportEdit.sensor.addTotalValue') }}
            </p>
            <!-- <el-switch
              :disabled="editedColumn.id !== column.id"
              :value="column.isHaveSensor"
              @change="changeEditSensorColumn(column)"
              class="mr-4"
            /> -->
            <div class="info" @focus="showInfoText" @blur="hideInfoText">
              <QuestionIcon />
              <p class="info-text hidden">
                {{ $t('reportEdit.sensor.hintText') }}
              </p>
            </div>
          </div>
          <div class="select w-full mt-4">
            <el-select :disabled="editedIndex === null || editedIndex !== index" class="w-full" v-model="column.total_ops" :placeholder="$t('new_units.placeholder.select')">
              <el-option v-for="item in getSensorValues" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
          </div>
        </div>
        <div v-if="isEdit && editedIndex === index" class="mt-2 flex w-full">
          <skif-button variant="normal" class="mt-4 mr-2 flex-1" @click="cancelEditing(index)">
            {{ $t('cancel') }}
          </skif-button>
          <skif-button class="mt-4 flex-1" @click="editFormulaAction(index)">
            {{ $t('change') }}
          </skif-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportsApi } from '@/api'
import { mapGetters } from 'vuex'

import EditIcon from './icons/Edit.vue'
import DeleteBtn from './assets/DeleteBtn.vue'
import FormulaList from './formulaList.vue'
import FormulaDropdown from './FormulaDropdown.vue'
import FormulaTags from './FormulaTags.vue'
import QuestionIcon from '@/components/unitsNew/assets/icons/question-icon.vue'

export default {
  components: {
    EditIcon,
    FormulaList,
    DeleteBtn,
    FormulaDropdown,
    FormulaTags,
    QuestionIcon
  },
  props: ['table', 'isAdd', 'isRemoveColSucess'],
  data() {
    return {
      currentColumnIndex: null,
      isDisabled: false,
      columnName: '',
      textarea: '',
      isNameMissing: false,
      showFormulaDropdown: false,
      searchInput: '',
      currentColumns: [],
      savedFormula: '',
      selectedFormulas: [],
      createdFormula: null,
      formattedText: '',
      oldData: {},
      isEdit: false,
      editedIndex: null,
      editedColumn: {
        id: ''
      },
      isEdited: false,
      isActionFinished: false,
      isFormulaWrong: false,
      isFormulaMissing: false,
      textareaValues: [],
      operations: ['+', '-', '*', '/', '(', ')'],
      currentFormulaIndex: null,
      totalValueReport: 'sum',
      isSelectTotalValue: false,
      isAddSelectTotalValue: false,
      sensorValue: '',
      sensorColumn: ''
    }
  },
  computed: {
    ...mapGetters({
      formulas: 'reports/formulasList',
      getSensorValues: 'dictionary/getSensorValues'
    }),
    filteredFormulas() {
      if (!this.searchInput) {
        return Object.entries(this.formulas)
      }

      const searchTerm = this.searchInput.toLowerCase()
      return Object.entries(this.formulas).filter(formula => formula[1].toLowerCase().includes(searchTerm))
    }
  },
  watch: {
    isRemoveColSucess(val) {
      console.log(this.currentColumnIndex, 'isRemoveColSucess')
      this.currentColumns.splice(this.currentColumnIndex, 1)
    },
    formulas(val) {
      // console.log('changed', val)
      // console.log('values', this.textareaValues)
    },
    isAdd(val) {
      if (val && this.isEdit) {
        this.currentColumns[this.editedIndex].name = this.oldData[this.editedIndex].name
        this.currentColumns[this.editedIndex].textarea = this.oldData[this.editedIndex].textarea

        this.editedIndex = null
        this.isEdit = false
      } else {
        this.clearFields()
      }
    },

    isActionFinished() {
      this.editFormulaHandler(this.editedColumn, this.editedIndex)
    }
  },
  methods: {
    setSensorColumn(item) {
      this.sensorColumn = item
    },
    showInfoText() {
      const info = document.querySelector('.info')
      info.classList.add('hovered')
    },

    hideInfoText() {
      const info = document.querySelector('.info')
      info.classList.remove('hovered')
    },

    dragTag(newArray, idx = null) {
      if (idx === null) {
        this.textareaValues = newArray

        return
      }

      this.currentColumns[idx].textareaValues = newArray
    },
    onEnterValue(numberOperand, idx = null) {
      if (numberOperand === null || !numberOperand.toString()) return

      const newNumberOperand = {
        value: numberOperand,
        name: numberOperand
      }

      if (idx === null) {
        this.textareaValues.push(newNumberOperand)
      } else {
        this.currentColumns[idx].textareaValues.push(newNumberOperand)
      }
    },
    addOperand(operand, idx = null) {
      const newOperand = {
        value: operand,
        name: operand
      }

      if (idx === null) {
        this.textareaValues.push(newOperand)
      } else {
        this.currentColumns[idx].textareaValues.push(newOperand)
      }
    },
    deleteParamFromFormula({ tag, idx }, columnIdx = null) {
      if (columnIdx === null) {
        this.textareaValues.splice(idx, 1)

        return
      }

      this.currentColumns[columnIdx].textareaValues.splice(idx, 1)
    },
    cancelEditing(idx) {
      this.currentColumns[idx].name = this.oldData[idx].name
      this.currentColumns[idx].textarea = this.oldData[idx].textarea
      this.currentColumns[idx].textareaValues = this.oldData[idx].textareaValues

      this.editedIndex = null
      this.isEdit = false
    },
    editFormulaAction(idx) {
      this.currentColumns[idx].isEdited = !this.currentColumns[idx].isEdited
    },
    async editFormulaHandler(item, idx) {
      const oldColData = this.oldData[idx]

      this.currentFormulaIndex = idx
      const column = {
        ...item,
        key: '',
        total_ops: item.total_ops
      }
      this.$emit('editcolumn', {
        column,
        oldName: oldColData.name
      })
      console.log(item)
      this.$store.commit('reports/remove_extrafield', oldColData.name)

      this.$store.dispatch('reports/add_extrafield', item.name)

      if (item.name && item.textareaValues.length) {
        this.currentColumns[idx].name = item.name
        this.currentColumns[idx].value = item.textareaValues.map(item => item.value).join(' ')
        this.currentColumns[idx].textareaValues = item.textareaValues
        this.currentColumns[idx].total_ops = item.total_ops
        const oldKey = `computable_columns.${oldColData.name}`
        const currentKey = `computable_columns.${item.name}`
        const oldDisplayValue = `Доп. Колонки/${oldColData.name}`
        const newDisplayValue = `Доп. Колонки/${item.name}`

        this.currentColumns.forEach(col => {
          const regex = new RegExp(`\\{${oldKey}\\}`, 'g')
          col.value = col.value.replaceAll(regex, `{${currentKey}}`)

          col.textareaValues.forEach(textareaItem => {
            if (textareaItem.value.includes('{') && textareaItem.value.includes('}') && textareaItem.value.includes(oldKey)) {
              textareaItem.value = textareaItem.value.replaceAll(oldKey, currentKey)
            }
            if (textareaItem.name === oldDisplayValue) {
              textareaItem.name = newDisplayValue
            }
          })
        })
        this.isEdit = false
        this.editedIndex = null
        this.editedColumn = {}

        this.$emit('saveFormulaChanges', this.currentColumns)
      }
    },
    changeSearchInput(value) {
      this.searchInput = value
    },
    changeTextareaValue(value) {
      this.textarea = value
    },
    changeColumnName(value) {
      this.columnName = value
    },
    editFormula(item, idx) {
      if (this.isEdit && this.editedIndex !== idx) return

      if (this.isAdd) {
        this.columnName = ''
        this.textarea = ''
        this.textareaValues = []

        this.$emit('closeAddingModal')
      }

      if (this.isEdit) {
        this.editedIndex = null
        this.editedColumn = {
          id: ''
        }

        this.currentColumns[idx].name = this.oldData[idx].name
        this.currentColumns[idx].textarea = this.oldData[idx].textarea
        // this.currentColumns.splice(idx, 1)
      } else {
        this.editedIndex = idx
        this.editedColumn = item
      }

      this.isEdit = !this.isEdit

      this.oldData[idx] = JSON.parse(JSON.stringify(this.currentColumns[idx]))
    },
    removeFormula(index) {
      this.currentColumnIndex = index
      if (this.isEdit && this.editedIndex === index) {
        this.isEdit = false
        this.editedIndex = null
      }

      if (this.isEdit && this.editedIndex !== index && this.editedIndex > index) {
        this.editedIndex -= 1
      }
      const col = this.currentColumns[index]

      this.$store.commit('reports/remove_extrafield', col.name)

      this.$emit('deleteColumn', col.id)
      // this.currentColumns.splice(index, 1)
      this.$emit('saveFormulaChanges', this.currentColumns)
    },
    addFormulaToTextarea(formula) {
      this.textareaValues.push({
        name: formula.formula[1],
        value: `{${formula.formula[0]}}`
      })

      this.showFormulaDropdown = false
    },
    editFormulaToTextarea(formula, idx) {
      this.currentColumns[idx].textareaValues.push({
        name: formula.formula[1],
        value: `{${formula.formula[0]}}`
      })

      this.currentColumns[idx].showFormulaDropdown = false
    },
    toggleFormulaDropdown() {
      this.showFormulaDropdown = !this.showFormulaDropdown
    },

    getFormulaValue(inputValue) {
      let value = inputValue
      const formulaKeys = Object.keys(this.formulas)

      formulaKeys.forEach(key => {
        const formulaValue = this.formulas[key]

        value = value.replace(new RegExp(formulaValue, 'g'), `{${key}}`)
      })

      return value
    },
    async addFormula() {
      const formula = {
        name: this.columnName,
        value: this.textareaValues.map(item => item.value).join(' ')
      }

      if (formula.name === '') {
        this.isNameMissing = true
        this.$emit('wrongformula', true)
        return
      }

      this.isNameMissing = false

      if (formula.value === '') {
        this.isFormulaMissing = true
        this.$emit('wrongformula', true)
        return
      }

      this.isFormulaMissing = false

      const newFormula = {
        name: formula.name,
        value: formula.value,
        textareaValues: this.textareaValues,
        filter_min: 0,
        filter_max: 1000,
        is_visible: true,
        isEdited: false,
        showFormulaDropdown: false,
        rightType: 'template_computable_columns',
        sort: '',
        key: '',
        total_ops: this.sensorColumn || 'empty',
        isHaveSensor: false
      }
      this.$emit('handleColumn', newFormula)

      this.$store.dispatch('reports/add_extrafield', formula.name)
      this.currentColumns.unshift(newFormula)
      this.$emit('closeformula', this.currentColumns)

      this.$emit('saveFormulaChanges', this.currentColumns)
    },
    clearFields() {
      this.textarea = ''
      this.columnName = ''
      this.textareaValues = []
    },
    checkBrackets(item, idx, values) {
      if (this.operations.includes(item))
        return {
          name: item,
          value: item
        }

      if (item.startsWith('{') && item.endsWith('}')) {
        const key = item.slice(1, item.length - 1)
        return {
          name: this.formulas[key],
          value: item
        }
      }

      if (!isNaN(item)) {
        return {
          name: item,
          value: item
        }
      }

      const nextItemIdx = idx + 1
      const nextItem = values[nextItemIdx]

      if (!nextItem) return

      const endsWithBracket = nextItem.endsWith('}')
      const rightItem = `${item} ${nextItem}`

      if (nextItem && endsWithBracket) {
        const key = rightItem.slice(1, rightItem.length - 1)

        return {
          name: this.formulas[key],
          value: rightItem
        }
      }
      this.checkBrackets(nextItem, nextItemIdx, values)
    }
  },

  mounted() {
    if (!this.table.template_computable_columns) {
      this.table.template_computable_columns = []
    }
    this.currentColumns = this.table.template_computable_columns.map(column => {
      const formulaKeys = column.value.match(/{(.*?)}/g)
      const textarea = column.value
      const { total_ops } = column

      if (formulaKeys) {
        let updatedTextarea = column.value
        formulaKeys.forEach(key => {
          const formulaKey = key.slice(1, -1)
          const formulaValue = this.formulas[formulaKey]

          if (formulaValue) {
            updatedTextarea = updatedTextarea.replaceAll(`{${key}}`, formulaValue)
          }
        })

        const pattern = /\{([^}]*)\}/g

        // Replace spaces inside curly braces with a unique delimiter
        const replacedText = textarea.replace(pattern, (match, group) => {
          return match.replace(/\s/g, '_!_')
        })

        // Split the modified text with spaces
        const splitText = replacedText.split(/\s+/)

        // Restore spaces inside curly braces
        const values = splitText.map(word => {
          const key = word.replace(/_!_/g, ' ')
          let keyValue = key

          if (key.startsWith('{')) {
            keyValue = key.slice(1, key.length - 1)
          }

          return {
            name: this.formulas[keyValue] || keyValue,
            value: key
          }
        })

        const obj = {
          ...column,
          value: textarea,
          showFormulaDropdown: false,
          textareaValues: values,
          isEdited: false,
          total_ops
        }

        return obj
      }

      return {
        ...column,
        value: textarea,
        showFormulaDropdown: false,
        isEdited: false,
        total_ops,
        textareaValues: column.value.split(' ').map(item => ({ name: item, value: item }))
      }
    })
  }
}
</script>

<style lang="styl" scoped>
.info-text {
  position: absolute;
  right: 20px;
  width 295px;
  z-index: 10;
  margin-top: 5px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #2767B6;
  background: #DBEBFF;
  border-radius: 6px;
}
.info:hover .info-text {
  display: block;
}

.sensor-reports-text {
  color: #80ABE1;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
</style>
